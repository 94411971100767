.customised-container {
    
}

.customised-container h2 {
    color: #333333;
}

.customised-container p {
    font-weight: 600;
    color: grey;
}

.customised {
    width: 60%;
    min-height: 450px;
    display: block;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.item-30 {
    width: 30%;
    margin: 5px;
    min-height: 100%;
    border-radius: 5px;
    background-position: center !important;
    background-size: 145% !important;
    background-repeat: no-repeat !important;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    cursor: pointer !important;
    transition: all 0.4s ease !important;
}

.item-30 h5,
.item-70 h5 {
    z-index: 20 !important;
    position: relative;
    color: #fff;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
}

.item-70 {
    width: 61.5%;
    margin: 5px;
    border-radius: 5px;
    min-height: 40px;
    background-position: left !important;
    background-size: 105% !important;
    background-repeat: no-repeat !important;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    cursor: pointer !important;
    transition: all 0.4s ease !important;
}

.item-30::before,
.item-70::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8));
    z-index: 10;
}

.item-70:hover, .item-30:hover{
    background-position: right !important;
}

@media screen and (max-width:992px) {
    .customised {
        width: 70%;
    }

    .item-70 {
        width: 62%;
    }

    .item-30 h5,
    .item-70 h5 {
        font-size: .8rem;
        font-weight: 700;
    }
}

@media screen and (max-width:600px) {
    .customised {
        width: 100%;
        min-height: 350px;
    }

    .item-70 {
        width: 62%;
    }

    .item-30 h5,
    .item-70 h5 {
        font-size: .7rem;
        font-weight: 700;
    }
    .item-30{
        background-size: cover !important;
        background-position: center !important;
    }
}

@media screen and (max-width:400px) {
    .customised {
        width: 100%;
        min-height: 300px;
    }

    .item-70 {
        width: 62%;
    }

    .item-30 h5,
    .item-70 h5 {
        font-size: .6rem;
        font-weight: 700;
    }
}