.avail__months {}

.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    /*box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);*/
    padding: 0 8px;
    border-radius: 5px;
    color: black !important;
    border: 2px solid #333333;
    text-transform: uppercase;
    font-weight: 600;
}

.circle button {
    background-color: #fff;
    color: black !important;
}

.circle:focus {
    background-color: #0038c5;
    color: #fff;
}

.circle.active {
    background-color: #0038c5;
    color: #fff !important;
}

.circle:hover {
    background-color: #0038c5;
    color: white;
}

.circle button {
    outline: none;
    border: 0;
    color: black;
}

.circle button:hover{
    outline: none;
    border: 0;
    background-color: #0038c5;
    color: white !important;
}

.btn__date {
    border: 1px solid #333333;
    background-color: transparent;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    margin: 0 3px;
}
.btn__date:hover {
    background-color: #0038c5;
}

.avail__dates p {
    cursor: pointer;
    font-size: 14px;
}

.avail__dates p:hover {
    cursor: pointer;
    font-size: 14px;
    background-color: #0038c5;
    color: #fff;
}

.book__now__button button {
    padding: 10px 40px;
    border-radius: 100px;
    color: #000;
    background-color: #fff;
    border: 2px solid #0038c5;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
    font-size: 18px;
    font-weight: 500;
    font-family: "Helvetica Neue", sans-serif;
    transition: all 0.3s ease;
}

.book__now__button button:hover {
    border: 2px solid #0038c5;
    background-color: #0038c5;
    color: #ffffff;
}

.costing__table tr th {
    padding: 12px;
    border: 2px solid black;
    font-size: 14px !important;
    color: #ffffff;
    background-color: #0038c5;
}
.costing__table tr td {
    font-size: 14px !important;
    padding: 12px;
}

@media screen and (max-width:478px) {
    .circle {
        padding: 0 8px;
    }

    .circle button {
        font-size: 12px;
    }

    .avail__months {
        width: 100%;
        overflow-x: scroll;
    }

    .book__now__button button {
        padding: 10px 20px;
        font-size: 3.5vw;
    }

    .avail__dates p {
        cursor: pointer;
        font-size: 10eropx;
    }
    .sidebar-costing{
        display: none;
    }
}