/* .review__card {
    border-radius: 15px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.5) 100%), url(https://imgtr.ee/images/2023/04/13/nHa4D.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 200px;
    padding: 30px 10px;
    box-sizing: border-box;
}

.review__card h5 {
    font-size: 16px;
}

.review__card p {
    font-size: 14px;
}


@media screen and (max-width:478px) {} */

.review__card {
    display: flex !important;
    height: 350px !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 35px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.review__card i {
    font-size: 25px;
}

.review__card .desc {
    text-align: center !important;
    font-size: 12px;
    font-style: italic;
    margin-bottom: 10px !important;
    margin: 0;
    padding: 0;
    margin-top: 10px;
}

.review__card img {
    margin-right: 10px;
    width: 100px;
    height: 100px;
    border-radius: 5px;
}

.review__card h5 {
    text-align: center;
    margin-top: 10px !important;
    font-size: 15px !important;
    font-weight: 600;
    margin: 0;
}

.review__card .date {
    font-size: 10px;
    text-align: center;
    margin: 0 !important;
    margin-top: 2px !important;
}

.video__cont .slick-slide {
    padding: 5px !important;
}