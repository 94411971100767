.success-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    font-family: 'Open Sans', sans-serif;
}

.success-header {
    text-align: center;
    margin-bottom: 30px;
}

.success-icon {
    color: #28a745;
    font-size: 64px;
    margin-bottom: 20px;
}

.success-title {
    color: #28a745;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    padding: 10px 0;
}

.success-subtitle {
    color: #6c757d;
    font-size: 16px;
    margin: 10px 0;
}

.success-card {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    padding: 20px;
}

.card-section {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e9ecef;
}

.card-section:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}

.section-title {
    font-size: 18px;
    font-weight: bold;
    color: #212529;
    margin-bottom: 15px;
}

.details-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

.detail-item {
    margin-bottom: 10px;
}

.detail-label {
    font-weight: bold;
    color: #495057;
    margin-bottom: 5px;
}

.detail-value {
    color: #212529;
}

.amount-paid {
    color: #28a745;
}

.amount-remaining {
    color: #ffc107;
}

.countdown-text {
    text-align: center;
    color: #6c757d;
    margin: 20px 0;
}

.countdown-number {
    color: #28a745;
    font-weight: bold;
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}


.success-container-whatsapp-button {
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    max-width: 300px;
    justify-content: center;
    transition: background-color 0.2s;
}

.success-container-whatsapp-button:hover {
    background-color: #218838;
}

.home-link {
    color: #007bff;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
}

.home-link:hover {
    color: #0056b3;
    text-decoration: underline;
}

.divider {
    text-align: center;
    color: #6c757d;
    margin: 10px 0;
}

/* Responsive styles */
@media (max-width: 768px) {
    .success-container {
        margin: 10px;
        padding: 10px;
    }

    .details-grid {
        grid-template-columns: 1fr;
    }

    .success-icon {
        font-size: 48px;
    }

    .success-title {
        font-size: 20px;
    }
}