.about__main {
    position: relative;
}

.icons__trip {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.i__con {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 5px;
}

.i__con i {
    font-size: 40px;
    color: #0038c5;
}

.light__text__cont {
    position: relative;
}

.light__text__cont h2 {
    border-left: 5px solid #333333;
    padding-left: 5px;
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 30px;
}

.light_text {
    position: absolute;
    font-size: 70px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.1);
    top: -25px;
    left: 0px;
}

.about__desc {
    font-weight: 200;
    font-size: 14px;
    font-family: "Helvetica Neue", sans-serif;
}

.contact__form button {
    /*border: 0;*/
    padding: 10px 40px;
    border-radius: 100px;
    color: #ffffff;
    background-color: #fff;
    border: 2px solid #0038c5;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
    font-size: 18px;
    font-weight: 500;
    font-family: "Helvetica Neue", sans-serif;
}

.contact__form button:hover {
    color: #ffffff;
    border: 2px solid #0038c5;
    background-color: #0038c5;
}

.content {
    display: none;
}

.show__content {
    display: block;
}


.about__tab__buttons button {
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #333333;
    color: #333333 !important;
    width: 30%;
    padding: 10px 0;
    font-weight: 600;
}

.about__tab__buttons button:hover {
    background-color: #0038c5;
    /*border: 1px solid #333333 !important;*/
    border: 0;
    color: #ffffff !important;
}

.about__tab__buttons button.active {
    background-color: #0038c5;
    border: 1px solid #000000;
    color: #ffffff !important;
}

.shd {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
    padding: 30px;
}

.shd-accordian {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
    padding: 30px 20px !important;
}

.about__main .hr-line {
    width: 50px;
}

.similar__trips__heading {
    font-weight: bold;
    text-align: start;
}


@media screen and (max-width:768px) {
    .things__to__carry {
        margin-bottom: 0 !important;
    }

    .icons__trip {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .about__tab__buttons {
        text-align: center;
    }

    .i__con {
        width: 60px;
        height: 60px;
    }

    .trip_icon {
        padding: 10px;
        border-radius: 50%;
        font-size: 25px;
    }


    .icons__trip p {
        font-size: 12px;
    }

    .icons__trip span {
        font-size: 13px;
    }

    .light_text {
        font-size: 59px;
        top: -10px;
        left: 0px;
    }

    .light__text__cont h2 {
        font-size: 25px;
    }

    .light__text__cont span {
        font-size: 55px;
    }

    .about__tab__buttons button {
        width: 30%;
        padding: 10px 0;
        font-size: 10px;
        font-weight: bold;
    }

    .similar__trips__heading {
        font-weight: bold;
        text-align: center !important;
    }
    .single-trip-form{
        display: none !important;
    }

}