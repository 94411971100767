.adminMain {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100%;
}

.adminLayout {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.adminSidebar {
    width: 25%;
    background-color: #111111;
}

.adminBody {
    width: 75%;
}

.adminSidebar p {
    padding: 20px 50px;
}

.adminSidebar a {
    color: white;
}

.adminSidebar a:hover {
    color: #0038c5;
}