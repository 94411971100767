.img__gallery img {
    border-radius: 10px !important;
    aspect-ratio: 3/2 !important;
    width: 250px !important;
    cursor: pointer !important;
}


@media screen and (max-width:600px) {
    .img__gallery img {
        border-radius: 10px !important;
        aspect-ratio: 3/2 !important;
        width: 200px !important;
    }
}

@media screen and (max-width:400px) {}