.promotional__banner {
    padding: 50px;
}

.promotional__banner img {
    width: 100%;
    object-fit: cover !important;
    border-radius: 15px;
    /* height: 360px; */
}

.promotional__banner .slick-slide {
    padding: 5px !important;
}

@media screen and (max-width:478px) {
    .promotional__banner {
        padding: 10px;
    }

    .promotional__banner img {
        width: 100%;
        height: 180px;
    }
}