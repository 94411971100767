.terms__page,
.privact__page,
.refund__page {
    margin-top: 100px !important;
}

.terms__page p,
.privact__page p {
    font-size: 16px;
}

.refund__page h4 {
    color: #777777;
}

@media screen and (max-width:478px) {

    .terms__page p,
    .privact__page p {
        font-size: 14px;
    }
}