.talk__to__bg {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.image__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 30px;
    padding: 20px 50px;
}

.talk__to__bg h2 {
    font-size: 1.5rem;
}

.talk__to__bg button {
    color: #ffffff;
    background-color: #0038c5;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 15px;
    padding: 10px 20px;
    text-transform: uppercase;
    font-size: 18px;
    font-family: "Helvetica Neue", sans-serif;
    font-weight: 600;
    margin-top: 10px;
}

@media screen and (max-width:992px) {

    .talk__to__bg {
        padding: 20px;
    }

    .image__button {
        padding: 30px;
    }

    .talk__to__bg h2 {
        font-size: 4vw;
        text-align: center;
    }

    .talk__to__bg button {
        font-size: .9rem;
        padding: 10px 20px;
        display: block;
        margin: 20px auto 5px auto;
        font-weight: 700;
    }
}
.bg-warning {
    background: linear-gradient(to right,#0038c5,  #0038c5, #0038c5);
}