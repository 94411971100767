.team__box {}

.about__heading {
    color: #474747;
    font-size: 28px;

}

.team__box img {
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
    border-radius: 100%;
    background: transparent;
    display: block;
    margin: auto;
}

.team__box h4 {
    color: #474747 !important;
    font-weight: 600;
    font-size: 20px !important;
}

.team__box p {
    font-weight: 300;
    font-size: 18px !important;
}

.team__box i {
    font-size: 25px;
    margin: 10px;
}
.about-5000{
    font-size: 200px !important;
    font-weight: bold;
    /*background-image: url('../../images/About/about.jpg');*/
    background-clip: text;
    -webkit-background-clip: text; /* For Safari/WebKit browsers */
    color: transparent;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    font-weight: bold;
    text-align: center;
}


@media screen and (max-width:478px) {
    .about__heading {
        color: #474747;
        font-size: 18px;
        font-weight: 700;
    }
    .about-5000{
        font-size: 100px !important;
    }
    .team__box img {
        width: 90%;
        box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
        border-radius: 100%;
        background: transparent;
        display: inline;
        margin: auto;
    }

    .team__box h4 {
        color: #474747 !important;
        font-weight: 600;
        font-size: .9rem !important;
    }

    .team__box p {
        font-size: .8rem !important;
        font-weight: 100;
    }

    .team__box i {
        font-size: 1rem !important;
        margin: px;
    }
    .about-small-text{
        font-size: 13px !important;
    }
}