.contact__form {
    transition: all 0.5s;
}

.form__heading {
    font-size: 30px;
}

.form__img img {
    width: 100px;
}

.fa-xmark {
    font-size: 25px;
    cursor: pointer;
    padding: 5px;
    border-radius: 10px;
}

form button {
    border: 1px solid black;
    padding: 10px 40px;
    border-radius: 7px !important;
    color: white;
    background-color: rgb(19, 36, 63);
    font-size: 18px;
    font-weight: 500;
    font-family: "Helvetica Neue", sans-serif;
}

.contact-form-input input:focus{
    outline: none !important;
}
.contact-form-input textarea:focus{
    outline: none !important;
}

.contact-form-input input, textarea{
    border: 2px solid rgb(180, 180, 180);
    font-size: 16px;
    background-color: transparent;
    padding: 7px !important;
    border-radius: 6px;
}
.contact-form-input input::placeholder{
    background-color: transparent;
}


.contact-form-input{
    position: relative;
}
.contact-form-lable{
    position: absolute;
    top: -1px;
    left: 30px;
    margin: 0;
    font-size: 13px;
    color: gray;
    background-color: white;
    padding: 0 10px;
}
.contact-submit-button:hover{
    background-color: white !important;
    color: black !important;
}

@media screen and (max-width:478px) {
    .form__heading {
        font-size: 16px;
        margin: 15px 0;
    }

    form label {
        font-size: 14px;
    }

    form button {
        border: 1px solid black;
        padding: 5px 30px !important;
        font-size: 14px !important;
        border-radius: 7px !important;
    }
    .contact__form button {
        padding: 10px 30px !important;
    }

    .form__img img {
        width: 50px;
    }

    .form__img p {
        font-size: 12px;
    }


}

/* @media screen and (max-width: 600px) {
    .contact__form {
        display: none !important;
    }

} */