.single-trip-bottom-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
    width: 100%;
    margin: 100px 0 !important;
}

.single-trip-bottom-icon-div{
    padding: 0 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.single-trip-bottom-icon-div img{
    width: 100px;
}


@media screen and (max-width:992px) {
    .single-trip-bottom-icon{
        display: flex;
        justify-content: space-between;
        margin: 50px 0 !important;
    }

    .single-trip-bottom-icon-div{
        padding: 0 !important;
    }

    .single-trip-bottom-icon-div img{
        width: 50px;
    }
    .single-trip-bottom-icon-div h6{
        font-size: 15px;
    }
}

@media screen and (max-width:600px) {
    .single-trip-bottom-icon-div img{
        width: 30px;
    }
    .single-trip-bottom-icon-div h6{
        font-size: 10px;
    }
}
