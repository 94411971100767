.dashboard__container {
    overflow: hidden;
}

.dashboard__container .cards {
    display: flex;
    flex-wrap: wrap;
}

.dashboard__container .card {
    width: 200px;
    min-height: 100px;
    margin: 5px;
    padding: 10px;
    background: linear-gradient(to bottom, #fecb2a, rgb(218, 178, 0));
    cursor: pointer;
}

.dashboard__container .card:hover {
    background: linear-gradient(to bottom, #fecb2a, rgb(195, 159, 0));
}

.dashboard__container .card i {
    font-size: 25px;
}

.dashboard__container .card .amount {
    font-size: 35px;
}

.updates__container {
    background-color: #fff2bc;
    border-radius: 5px;
}

.updates__table tr {
    font-size: 12px !important;
}

.updates__table tr td {
    font-size: 10px !important;
}