.category__cont h2 {
    border-left: 5px solid #333333;
    padding: 10px;
}

.category__cont select {
    color: #333333;
    border: 1px solid #333333;
    padding: 5px;
    border-radius: 5px;
    margin: 10px 5px;
    outline: none;
}

.trip__container {
    /*border: 1px solid rgba(0, 0, 0, 0.2);*/
    width: 360px;
    padding-left: 0;
    border-radius: 10px;
    overflow: hidden;
    display: block;
    /*margin: 0 auto;*/
    margin-bottom: 20px;
    cursor: pointer;
}

.trip__img__container {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    height: 450px;
    /*margin-left: -10px;*/
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.trip__price__tag_custom {
    position: absolute;
    top: 10px; /* Adjust as per your design */
    left: 0; /* Adjust as per your design */
    background-color: #0038c5; /* Example background color */
    color: #ffffff; /* Text color */
    padding: 5px 10px; /* Padding inside the tag */
    border-radius: 5px;
    font-weight: bold;
    z-index: 2; /* Ensure it stays on top of the image */
}

/*.trip__container {*/
/*    border: 1px solid rgba(0, 0, 0, 0.2);*/
/*    width: 360px; !* Set width to match the image width *!*/
/*    !*height: 800px; !* Set height to 20% of the image height, assuming the image height is 500px *!*!*/
/*    border-radius: 10px;*/
/*    overflow: hidden;*/
/*    display: block;*/
/*    margin-bottom: 20px;*/
/*    cursor: pointer;*/
/*    box-sizing: border-box; !* Ensures padding and border are included in the element's width and height *!*/
/*}*/

/*.trip__img__container {*/
/*    border-radius: 10px;*/
/*    overflow: hidden;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background-position: center !important;*/
/*    background-repeat: no-repeat !important;*/
/*    background-size: cover !important;*/
/*}*/

/*    width: 95% !important;
    height: 500px;
    border-radius: 15px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    /*width: 80%;*/
    /*height: 50%;*/
    /*border-radius: 1.5vw;*/
    /*transition: all 0.2s ease;*/
    /*scale: 1;*!*/
/*}*/

.other__content {
    margin-bottom: 10px;
    padding: 10px !important;
    width: 100% !important;
}

.dates p {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
    .dates p {
        font-size: 12px;
    }
    .trip__title{
        font-size: 15px !important;
    }
}