.content {
    display: none;
}

.show {
    display: block;
}

.book__cont {
    border: 3px solid #0038c5;
    border-radius: 15px;
    overflow: hidden;
    min-height: 300px;
    color: #333333;
}

.book__cont .bg-dark{
    background-color: #0038c5 !important;
}

.book__cont .bg-dark{
    background-color: #0038c5 !important;
}

.border__date {
    border: 1px solid #0038c5;
    padding: 15px;
    border-radius: 10px;
}

.date__btn {
    padding: 8px 20px;
    border-radius: 15px;
    cursor: pointer;
    border: 1px solid #0038c5;
    background-color: transparent;
    transition: 0.3s all;
}

.date__btn.active {
    background-color: #0038c5;
    border: 1px solid #333333;
    color: white !important;
}

.booking__btn__cont {
    position: relative;
}

.btn-dark {
    background-color: #0038c5;
}

.arrow__left,
.arrow__right {
    padding: 5px 8px;
    border: 0;
    background-color: white;
    color: #0038c5;
    border-radius: 5px;
    position: absolute;
    top: -38px;
}

.arrow__left {
    left: 200px;
}

.arrow__right {
    right: 200px;
}

@media screen and (max-width:380px) {

    .book_th {
        font-size: 11px !important;
    }

    
}

@media screen and (max-width:600px) {
    .booking__page h1 {
        font-size: 20px !important;
    }

    .book_th {
        font-size: 15px;
    }

    .fa-add {
        font-size: 14px;
    }

    .fa-minus {
        font-size: 14px;
    }

    .arrow__left,
    .arrow__right {
        padding: 5px 8px;
        top: -38px;
    }

    .arrow__left {
        left: 50px;
    }

    .arrow__right {
        right: 50px;
    }
    
}

@media screen and (max-width:990px) {

    .arrow__left,
    .arrow__right {
        padding: 5px 8px;
        top: -38px;
    }

    .arrow__left {
        left: 50px;
    }

    .arrow__right {
        right: 50px;
    }
}