.footer {
    /*background-color: #333333;*/
    padding: 50px !important;
    background-color: #0038c5;
}

.page__links {
    display: flex;
    justify-content: center;
}

.page__links a {
    color: white;
    margin: 0 20px;
    font-size: 20px;
    font-family: "Helvetica Neue", sans-serif;
}

.trip__locations {
    margin-top: 20px;
    justify-content: center;
}

.trip__locations li a {
    color: white;
    margin: 0 20px;
    font-weight: 300;
    font-family: "Helvetica Neue", sans-serif;
    text-transform: uppercase;
}

.social__media__links {
    background-color: #fff;
}

.social__media__links i {
    color: #000;
    background-color: #aa9999;
    border-radius: 100%;
    font-size: 20px;
    padding: 10px;
    margin: 0 15px;
}

.footer__address {
    padding: 10px 20px 30px 20px;
}

.footer__address h6 {
    font-weight: 600;
    font-size: 14px;
}

.footer__address p {
    font-weight: 200;
}

.email__number__website {
    width: 500px;
    display: block;
    margin: auto;
}

.email__number__website p {
    font-weight: 500;
    font-size: 14px;
}

.copyright__text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-color: #0038c5;
    color: white;
}

.copyright__text h5 {
    font-size: 14px;
}

@media screen and (max-width:478px) {
    .email__number__website {
        width: 100%;
    }

    .email__number__website p {
        font-size: 12px;
    }

    .copyright__text {
        padding-bottom: 80px !important;
    }
}