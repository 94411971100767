.testimonials__cont {
    width: 100%;
}

.testimonials {
    position: relative;
}

.testimonials__cont .hr-line {
    width: 300px;
}

.testimonials__cont h2 {
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
}

.testimonials iframe {
    border-radius: 100px;
    width: 700px;
    height: 392px;
}

.testimonials button i {
    color: #333333;
}

@media screen and (max-width:992px) {

    .testimonials {
        position: relative;
    }

    .testimonials__cont h2 {
        font-size: 1.5rem;
        font-weight: 700;
        text-transform: capitalize;
    }

    .testimonials__cont .hr-line {
        width: 100px;
    }

    .testimonials__cont p {
        font-weight: 500;
    }

    .testimonials iframe {
        border-radius: 50px;
        width: 100%;
        height: 200px;
    }

}