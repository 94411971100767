.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
}

.slick-track {
    margin: 0 !important;
}