.slider__trip {
    position: relative;
    padding: 0.5rem !important;
}

.slider__trip .blog-title {
    font-size: 18px !important;
}

.slider__trip .blog-para {
    font-size: 15px !important;
}
.slider__trip .blog-div {
    margin-top: -6px !important;
    margin-right: 20px !important;
}

.trips {
    width: 300px;
    cursor: pointer !important;
    box-sizing: border-box;
    overflow: hidden;
}

/*.trending__banner {*/
/*   */
/*}*/

.trips .trip__image {
    width: 95% !important;
    height: 50% !important;
    border-radius: 15px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    display: inline-block;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    /*width: 80%;*/
    /*height: 50%;*/
    /*border-radius: 1.5vw;*/
    transition: all 0.2s ease;
    scale: 1;
    object-fit: cover;
}

.trip__image__container {
    position: relative;
    overflow: hidden
}

.trip__price__tag {
    position: absolute;
    top: 10px; /* Adjust as per your design */
    left: 10px !important; /* Adjust as per your design */
    background-color: #0038c5; /* Example background color */
    color: #ffffff; /* Text color */
    padding: 5px 10px; /* Padding inside the tag */
    border-radius: 5px;
    font-weight: bold;
    z-index: 2; /* Ensure it stays on top of the image */
}

.trip__image__container .trip__image__gradient {
    position: relative;
    width: 95% !important;
    bottom: -10px;
    left: 10px;
    height: 30%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    z-index: 1;
}


.trips:hover img {
    scale: 1;
}

.trips h5 {
    font-family: "Helvetica Neue", sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #333333;
}

.price {
    color: #333333;
    font-weight: 600 !important;
}

.date__time i {
    font-size: 16px;
}

.trips p {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    font-family: "Helvetica Neue", sans-serif;
    color: rgb(95, 95, 95);
}

.trips i {
    color: #0038c5;
    font-size: 15px;
}

.trips h6 {
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 600;
    font-family: "Helvetica Neue", sans-serif;
    color: #0038c5;

}

.slider__trip .slick-list {
    padding: 0px !important;
}

.dates {
    min-width: 250px;
    display: flex;
    flex-wrap: wrap !important;
}

.dates i {
    color: #0038c5;
    font-size: 15px;
}

.dates span {
    color: rgb(122, 122, 122);
    font-size: 15px;
    font-weight: 600;
}

.prevArrow,
.nextArrow {
    position: absolute;
    top: 30%;
    border: 0;
    font-size: 4rem;
    padding: 10px;
    background-color: transparent;
    color: #eeb419;
}

.slider__trip .arrow__left,
.slider__trip .arrow__right {
    background-color: #000000c9 !important;
    color: white;
    position: absolute;
    top: 150px;
    padding: 25px 0;
    width: 30px;
}

.slider__trip .arrow__left {
    left: -25px;
}

.slider__trip .arrow__left:hover {
    background-color: #4e4e4ec9 !important;
}

.slider__trip .arrow__right {
    right: -5px;
}

.slider__trip .arrow__right:hover {
    background-color: #4e4e4ec9 !important;
}

@media screen and (max-width:1000px) {
    .trips img {
        width: 95% !important;
        height: 40% !important;
        border-radius: 15px;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        scale: 1;
        object-fit: cover;

    }
}
@media screen and (max-width:600px) {
    .trips img {
        width: 95% !important;
        height: 40% !important;
        border-radius: 15px;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        scale: 1;
        object-fit: cover;

    }
}

@media screen and (max-width:992px) {
    .slider__trip .blog-title {
        font-size: 15px !important;
    }
    .slider__trip .blog-para {
        font-size: 11px !important;
    }
    .slider__trip .blog-div {
        margin-right: 10px !important;
    }
    .trips {
        min-width: 100px;
        width: 100%;
        padding: 0 0 0 0;
    }

    .trips img {
        width: 95% !important;
        height: 40% !important;
        border-radius: 15px;
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-size: cover !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        scale: 1;
        object-fit: cover;

    }

    .date__time i {
        font-size: 20px;
    }

    .trips p {
        font-size: 3vw;
        margin-top: 5px;
        margin-bottom: 0;
    }

    .trips h5 {
        font-size: 3.5vw;
        min-width: 220px;
        font-weight: 700;
    }

    .dates i {
        font-size: 20px;
    }

    .dates span {
        font-size: 12px;
    }

    /* .slider__trip .slick-slide {} */

    .slider__trip .arrow__left,
    .slider__trip .arrow__right {
        display: none;
        position: absolute;
        padding: 10px;
        top: 0;
    }
}

@media screen and (max-width:400px) {
    .date__time i {
        font-size: .9rem;
    }

    .trips p {
        font-size: .65rem;
    }

    .trips h5 {
        font-weight: 700;
        font-size: .8rem;
    }

    .dates span {
        font-weight: 600;
        font-size: 10px;
    }

    .slider__trip .arrow__left,
    .slider__trip .arrow__right {
        display: none;
        position: absolute;
        padding: 10px;
        top: 0;
    }
}